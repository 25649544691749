import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'removeServiceName',
})
export class RemoveServiceNamePipe implements PipeTransform {
  transform(value: string, args?: any): any {
    return value.replace('rmi-calls-api-' + environment.stage + '-', '');
  }
}
