<div class="search-warper">
  <input
    #globalSearchInput
    class="search-warper__input"
    type="text"
    pInputText
    placeholder="Search"
    (input)="getEventValueInput($event)"
    (keydown.enter)="getEventValue($event)"
    pTooltip="Type your term and press ENTER"
    tooltipPosition="bottom"
  />
  <i
    (click)="enterSearchInput()"
    class="search-warper__icon pi pi-search cursor-pointer"
  >
  </i>
</div>
