<div class="d-flex align-items-center justify-content-center">
    <div class="form wrapper" *ngIf="state$ | async as state">
      <div class="form-head">
        <span class="form-head-text"> Edit application</span>
      </div>
      <div class="w-100 p-2">
        <form
        [formGroup]="form"
        (ngSubmit)="submit()"
        >
  
        <div class="field">
          <label for="email" class="form-label"> Application Name </label>
          <div class="input-label">
            <input
              type="text"
              class="form-control"
              id="appName"
              formControlName="appName"
            />
          </div>
        </div>
  
        <div class="d-flex form-btn-wrapper">
          <button
            class="btn primary"
            [disabled]="state === formState.LOADING"
            [ngClass]="{ disabled: state === formState.LOADING }"
            type="submit"
          >
            <span>Save</span>
            <i
              class="pi pi-spin pi-spinner"
              *ngIf="state === formState.LOADING"
              style="font-size: 21px"
            ></i>
          </button>
        </div>
  
        <div class="d-felx unknown-error" *ngIf="state === formState.ERROR && !showErrors">
          <div *ngIf="errorMessage !== undefined; else defaultError">
            {{ errorMessage }}
          </div>
          <ng-template #defaultError>
            Something went wrong, please try again.
          </ng-template>
        </div>
        <div *ngIf="showErrors" class="d-flex flex-column justify-content-center mt-3">
          <div
          class="error-text"
          *ngIf="
            form.controls.appName.touched && form.controls.appName.errors?.required
          "
        >
          Application name is required
        </div>
        </div>
        
        </form>
      </div>
    </div>
  </div>
  
