import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorHandlerServiceInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        let errorMessage = 'something went wrong';
        if (!err) {
          errorMessage = 'something went wrong';
          return throwError({ message: errorMessage });
        }
        if (typeof err === 'string') {
          errorMessage = err;
          return throwError({ message: errorMessage });
        }
        if (err.error && typeof err.error === 'string') {
          errorMessage = err.error;
          return throwError({ message: errorMessage });
        }
        if (err.error.Message && typeof err.error.Message === 'string') {
          errorMessage = err.error.Message;
          return throwError({ message: errorMessage });
        }
        if (err.error.detail && typeof err.error.detail === 'string') {
          errorMessage = err.error.detail;
          return throwError({ message: errorMessage });
        }
        if (err.message && typeof err.message === 'string') {
          errorMessage = err.message;
          return throwError({ message: errorMessage });
        }

        return throwError({ ...err, message: errorMessage });
      })
    );
  }
}
