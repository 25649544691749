<div class="d-flex align-items-center justify-content-center">
    <div class="form wrapper" *ngIf="state$ | async as state">
      <div class="w-100">
        <p-steps [model]="items" [(activeIndex)]="activeIndex" [readonly]="true" [styleClass]="'new-app-steps'"></p-steps>
        <div [className]="activeIndex === 0 ? 'active' : 'inactive'">
          <form
          [formGroup]="form"
          (ngSubmit)="submit()"
          >

          <div class="field">
            <label for="email" class="form-label"> Application Name </label>
            <div class="input-label">
              <input
                type="text"
                class="form-control"
                id="appName"
                formControlName="appName"
              />
            </div>
          </div>

          <div class="d-flex form-btn-wrapper">
            <button
              class="btn primary"
              [disabled]="state === formState.LOADING"
              [ngClass]="{ disabled: state === formState.LOADING }"
              type="submit"
            >
              <span>Create</span>
              <i
                class="pi pi-arrow-right"
                *ngIf="state !== formState.LOADING"
                style="font-size: 18px"
              ></i>
              <i
                class="pi pi-spin pi-spinner"
                *ngIf="state === formState.LOADING"
                style="font-size: 18px"
              ></i>
            </button>
          </div>

          <div class="d-felx unknown-error" *ngIf="state === formState.ERROR && !showErrors">
            <div *ngIf="errorMessage !== undefined; else defaultError">
              {{ errorMessage }}
            </div>
            <ng-template #defaultError>
              Something went wrong, please try again.
            </ng-template>
          </div>
          <div *ngIf="showErrors" class="d-flex flex-column justify-content-center mt-3">
            <div
            class="error-text"
            *ngIf="
              form.controls.appName.touched && form.controls.appName.errors?.required
            "
          >
            Application name is required
          </div>
          </div>
          </form>
        </div>
        <div [className]="activeIndex === 1 ? 'active' : 'inactive'">
          <div>
            <div class="description-pl mt-1">
              <p>
                The API Key and Secret Key below are long, randomly generated tokens that provide an authentication method for our API. Click “Copy to Clipboard” button to grab each one.
              </p>
            </div>
          </div>

          <div class="mt-5">
            <form>
              <div class="field">
                <label class="form-label"> API Key </label>
                <div class="copy-input">
                  <input type="text" class="form-control" placeholder="{{ apiKey }}" disabled>
                  <span #spanApiKey id="span-apikey" class="copy-button" [life]="1500" tooltipEvent="click" pTooltip="Copied" tooltipPosition="top" (click)="copyText(spanApiKey, apiKey)"><img src="/assets/copy-application.svg" /></span>
                </div>
              </div>
              <div class="field mt-3">
                <label class="form-label"> Secret Key </label>
                <div class="copy-input">
                  <input type="text" class="form-control" placeholder="{{ secretKey }}" disabled>
                  <span #spanSecretKey id="sp-secretkey" class="copy-button" [life]="1500" tooltipEvent="click" pTooltip="Copied" tooltipPosition="top" (click)="copyText(spanSecretKey, secretKey)"><img src="/assets/copy-application.svg" /></span>
                </div>
              </div>
              <div class="d-flex form-btn-wrapper">
                <button class="btn primary" (click)="stepChange(1)">
                  <span>Next</span>
                  <i
                    class="pi pi-arrow-right"
                    style="font-size: 18px"
                  ></i>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div [className]="activeIndex === 2 ? 'active' : 'inactive'">
          <div>
            <div class="mt-3">
              <span *ngFor="let pl of programLanguages">
                <button class="btn btn-pl" [ngClass]="{ 'active' : isActive === pl.id }" (click)="changeProgramLanguage(pl.id)">
                  <img class="me-1" src="/assets/{{ pl.name }}.svg" alt="{{ pl.title }}" /> {{ pl.title }}
                </button>
              </span>
            </div>
            <div class="description-pl mt-3">
              <p>
                In the terminal, issue a command that follows this syntax and replace _myKeY123 with a valid API Key:
              </p>
            </div>
            <div class="field mt-3">
              <div class="copy-input textarea-format">
                <textarea class="form-control content-pl" readonly="readonly">{{ programLanguageContent }}</textarea>
                <span #spanPl id="span-pl" class="copy-button" [life]="1500" tooltipEvent="click" pTooltip="Copied" tooltipPosition="top" (click)="copyText(spanPl, programLanguageContent)"><img src="/assets/copy-application.svg" /></span>
              </div>
            </div>
            <div class="d-flex form-btn-wrapper">
              <button class="btn primary" (click)="saveButton()"><span>Finish</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
