import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'rmi-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent {
  @Input() searchText: string | undefined = '';
  @Output() searchEnterEvent = new EventEmitter<string>();
  @ViewChild('globalSearchInput') globalSearchInput!: ElementRef;

  getEventValue($event: Event) {
    this.searchText = ($event.target as HTMLInputElement).value;
    this.searchEnterEvent.emit(this.searchText);
  }

  getEventValueInput($event: Event) {
    this.searchText = ($event.target as HTMLInputElement).value;
  }

  enterSearchInput() {
    this.searchEnterEvent.emit(this.searchText ?? '');
  }

  constructor() {}
}
