<div class="container-md">
    <div class="d-flex flex-row align-items-start justify-content-center">
        <img class="d-none d-lg-inline sparks-img" src="/assets/spark-left.svg" />
        <div class="wrapper">
            <div class="message-box">
                <h2  class="text-center">Page not found!</h2>
            </div>
        </div>
        <img class="d-none d-lg-inline sparks-img" src="/assets/spark-right.svg" />
    </div>
</div>
  