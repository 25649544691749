export async function encryptPassword(password: string, publicKey: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(password);
  const encryptedData = await window.crypto.subtle.encrypt(
    {
      name: 'RSA-OAEP',
    },
    await importPublicKey(publicKey),
    data
  );
  const base64EncryptedData = window.btoa(
    String.fromCharCode(...new Uint8Array(encryptedData))
  );

  return base64EncryptedData;
}

async function importPublicKey(pemPublicKey: string) {
  // Remove the PEM header and footer
  const base64PublicKey = pemPublicKey
    .replace('-----BEGIN PUBLIC KEY-----', '')
    .replace('-----END PUBLIC KEY-----', '')
    .replace(/\s+/g, '');

  // Convert the Base64 string to an ArrayBuffer
  const publicKeyBuffer = Uint8Array.from(atob(base64PublicKey), (c) =>
    c.charCodeAt(0)
  ).buffer;

  // Import the public key as a CryptoKey object
  const publicKey = await window.crypto.subtle.importKey(
    'spki',
    publicKeyBuffer,
    {
      name: 'RSA-OAEP',
      hash: 'SHA-256',
    },
    true,
    ['encrypt']
  );
  return publicKey;
}
