// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { StageName } from 'src/app/models/enums/stageName';

export const environment = {
  stage: StageName.Development,
  apiUrl: 'https://api.dev.roastmyivr.com/v1',
  x_api_key: 'AwNWtEtudja6g4nwPSNua5MB2VfLSU2gaa6Itp3A',
  authCookieName: StageName.Development + '-ivr-auth',
  websiteLandingUrl: 'https://dev.roastmyivr.com',
  websiteReportUrl: 'https://report.dev.roastmyivr.com',
  websitePortalUrl: 'https://developer.dev.roastmyivr.com',
  passwordPublicKeyBase64:
    'LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlJQklqQU5CZ2txaGtpRzl3MEJBUUVGQUFPQ0FROEFNSUlCQ2dLQ0FRRUFuMWsvT1JqNnllVUhEcmJJU1NFYwpjZm9UdkZtZndwSWlvbTVwQXRGV1c5WnpjUlpOS1pocWVGN1hEaWw4R0s4bTBTUzE4QzRQQ0wxZWdIcGV5d2c5CjVUWmFZKzdWR1lueUNYeHZ4VGxncFFFQ25VTWJ0L2Q0Z0s5TWtzSXo2MXFhZkNJc0F5eStONGVwQ2RiZmRhRDYKYU1CRG5PVmNpQW05dXE1MEdsbCtQUnVwTEJRNlM4dHhwRHh5cmZ2L3llamlrU3pBWWtvaXlzSnNmYVJTdy9IbApnVVBkTENJR2ErRTNGZFRFMVppWTR0dzVXWndVWEtmaTBsSE9kT0liZEFHR0RqRmRaMFM1TjZ6WTZUYnZGTUptCnM3OGhhM1dRSUt6OGhKbHBLSDRiY01NMGZ3TUR5bVNrUis1NWxkdjQvTCt4L1ltV1JHRmY0a1ZaY3pDcTNzMDMKQ1FJREFRQUIKLS0tLS1FTkQgUFVCTElDIEtFWS0tLS0tCg==',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
