import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Application } from 'src/app/models/applications.model';
import { FormState } from 'src/app/models/enums/formState.enum';
import { HttpRequestsService } from 'src/app/services/http-requests.service';

@Component({
  selector: 'rmi-edit-application',
  templateUrl: './edit-application.component.html',
  styleUrls: ['./edit-application.component.scss'],
})
export class EditApplicationComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  public loading: boolean = true;

  showErrors = false;
  errorMessage?: string;
  formState = FormState;
  public state$ = new BehaviorSubject(FormState.READY_FOR_SUBMISSION);
  form = this.fb.group({
    appName: ['', [Validators.required]],
  });

  application: Application | undefined;

  constructor(
    private fb: FormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private httpRequests: HttpRequestsService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.form.controls['appName'].setValue(this.config.data.appName);
  }

  public submit(): void {
    this.loading = true;
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.form.disable();
      this.state$.next(FormState.LOADING);
      this.httpRequests
        .editApplication(
          this.config.data.appId,
          this.form.controls['appName'].value
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response) => {
            this.loading = false;
            this.close();
            this.messageService.add({
              severity: 'success',
              summary: 'RMI Service',
              detail: 'Patching new name succeeded!',
            });
          },
          error: (err: HttpErrorResponse) => {
            this.loading = false;
            this.messageService.add({
              severity: 'error',
              summary: 'RMI Service',
              detail: 'Patching new name failed!\n' + `${err.message}`,
            });
          },
        });
    } else {
      this.showErrors = true;
    }
  }

  close() {
    this.ref.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
