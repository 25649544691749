<div
  class="d-flex flex-column align-items-center bg-white rounded-3 position-relative modal-warper"
>
  <img
    (click)="closeCaptchaModal()"
    class="cross-btn"
    width="18"
    height="18"
    src="../../../../assets/cross.svg"
  />
  <h2 class="mt-40px text-center d-inline-block header">Human verification</h2>
  <p class="mt-4 mx-5 mb-4 text-center d-inline-block explanation">
    We're sorry for the confusion but we can't quite tell if you're a person or
    a script. Please answer the question to clear all doubts.
  </p>
  <div class="w-100 p-3" *ngIf="state$ | async as state">
    <form
      class="form p-32px"
      [formGroup]="form"
      (ngSubmit)="submit()"
      *ngIf="
        state === formState.LOADING ||
        state === formState.READY_FOR_SUBMISSION ||
        state === formState.ERROR
      "
    >
      <div class="field py-0" *ngIf="captchaQuestion; else loading">
        <label for="answer" class="form-label">{{ captchaQuestion }}</label>
        <input
          type="text"
          class="form-control"
          id="answer"
          formControlName="answer"
          (input)="onInput()"
        />

        <div class="w-100 container-height">
          <div
            class="error-text"
            [style.visibility]="
              (form.controls.answer.touched &&
                form.controls.answer.errors?.required) ||
              state === formState.ERROR
                ? 'visible'
                : 'hidden'
            "
          >
            {{
              state === formState.ERROR ? errorResponse : "Answer is required"
            }}
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button
          *ngIf="
            state === formState.READY_FOR_SUBMISSION ||
            state === formState.ERROR
          "
          class="btn primary text-center"
          type="submit"
        >
          <span>I'm Human</span>
        </button>
        <button
          *ngIf="state === formState.LOADING"
          class="btn primary disabled"
          disabled
        >
          <span
            class="d-flex align-items-center justify-content-center button-wrapper"
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </span>
        </button>
      </div>
    </form>
  </div>
</div>

<ng-template #loading class="btn primary disabled" disabled>
  <span
    class="loading-container d-flex align-items-center justify-content-center spinner-wrapper"
  >
    <span
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    ></span>
  </span>
</ng-template>
