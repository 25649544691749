import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StepsModule } from 'primeng/steps';

import { ToolbarMainComponent } from './components/toolbar-main/toolbar-main.component';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { ApiKeyInterceptor } from './interceptor/api-key.interceptor';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { RemoveServiceNamePipe } from './pipes/remove-service-name.pipe';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { FormatTimePipe } from './pipes/format-time.pipe';
import { CreateNewApplicationComponent } from './components/create-new-application/create-new-application.component';
import { EditApplicationComponent } from './components/edit-application/edit-application.component';
import { CaptchaComponent } from './components/captcha/captcha.component';
import { ErrorHandlerServiceInterceptor } from './interceptor/error-handler-service.interceptor';
import { SearchInputComponent } from './components/search-input/search-input.component';

@NgModule({
  declarations: [
    ToolbarMainComponent,
    FormatDatePipe,
    RemoveServiceNamePipe,
    FormatTimePipe,
    CreateNewApplicationComponent,
    EditApplicationComponent,
    CaptchaComponent,
    SearchInputComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, StepsModule],
  exports: [
    ToolbarMainComponent,
    FormatDatePipe,
    RemoveServiceNamePipe,
    FormatTimePipe,
    SearchInputComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiKeyInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerServiceInterceptor,
      multi: true,
    },
  ],
})
export class SharedModule {}
