import { AuthenticationData } from './../models/authentication.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { UserRole } from '../models/enums/userRole';
import { Captcha } from '../models/captcha.model';
import { encryptPassword } from '../shared/utilities/crypto';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private AuthAPI = environment.apiUrl + '/admin/auth';
  private readonly HOST_NAME = location.hostname.replace(/^admin/, '');
  private signInAPI = this.AuthAPI + '/sign-in';
  private signUpAPI = this.AuthAPI + '/sign-up';
  private registrationConfirmAPI = this.AuthAPI + '/confirm';
  private resendConfirmCodeAPI = this.AuthAPI + '/confirm/resend';
  private emailVerifierAPI = environment.apiUrl + '/email-verifications/verify';
  private captchaAPI = environment.apiUrl + '/captcha';

  lastVisit: BehaviorSubject<number | undefined> = new BehaviorSubject<
    number | undefined
  >(undefined);

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService
  ) {}

  signIn(params: {
    username: string;
    password: string;
  }): Observable<AuthenticationData> {
    const headers = { skip: 'true' };
    return this.http.post<AuthenticationData>(this.signInAPI, params, {
      headers,
    });
  }

  signUp(params: {
    name: string;
    email: string;
    username: string;
    password: string;
    captcha: Captcha;
  }): Observable<AuthenticationData> {
    localStorage.clear();
    const headers = { skip: 'true' };
    return this.http.post<AuthenticationData>(this.signUpAPI, params, {
      headers,
    });
  }

  registrationConfirm(params: {
    username: string;
    confirmationCode: string;
  }): Observable<void> {
    const headers = { skip: 'true' };
    return this.http.post<void>(this.registrationConfirmAPI, params, {
      headers,
    });
  }

  resendConfirmCode(username: string): Observable<void> {
    const body = { username: username };
    const headers = { skip: 'true' };
    return this.http.post<void>(this.resendConfirmCodeAPI, body, {
      headers,
    });
  }

  emailVerifier(email: string): Observable<void> {
    const headers = { skip: 'true' };
    return this.http.post<void>(this.emailVerifierAPI, { email }, { headers });
  }

  getNewCaptcha(): Observable<Captcha> {
    const headers = { skip: 'true' };
    return this.http.get<Captcha>(this.captchaAPI, { headers });
  }

  validateCaptcha(answeredCaptcha: Captcha): Observable<void> {
    const headers = { skip: 'true' };
    return this.http.post<void>(this.captchaAPI, answeredCaptcha, { headers });
  }

  validateAccessToken(): Observable<string> {
    return new Observable<string>((subscriber) => {
      const ivrAuth =
        this.cookieService.get(environment.authCookieName) || null;
      if (!ivrAuth) {
        localStorage.setItem('redirectTo', this.router.url);
        this.router.navigate(['/auth/sign-in']);
        return;
      }

      try {
        subscriber.next(`Bearer ${ivrAuth}`);
        subscriber.complete();
      } catch (_) {
        subscriber.next('');
        subscriber.complete();
      }
    });
  }

  login(expiresIn: string, accessToken: string): void {
    const now = new Date();
    const expiresInFromNow = new Date(now.getTime() + +expiresIn * 1000);
    this.cookieService.set(
      environment.authCookieName,
      accessToken,
      expiresInFromNow,
      '/',
      this.HOST_NAME,
      true
    );
  }

  logout(): void {
    localStorage.clear();
    this.cookieService.deleteAll('/', this.HOST_NAME);
    if (this.router.url !== '/auth/sign-in') {
      this.router.navigate(['/auth/sign-in']);
    }
  }

  isLoggedIn() {
    const auth = this.cookieService.get(environment.authCookieName) || null;
    if (auth) {
      return true;
    }
    return false;
  }

  getRoles(): UserRole[] {
    try {
      const auth = this.cookieService.get(environment.authCookieName) || null;
      if (auth) {
        let decodedJWT = JSON.parse(window.atob(auth.split('.')[1]));
        const groups: string[] = decodedJWT['cognito:groups'];
        const roles: UserRole[] = [];
        groups.forEach((element) => {
          const rolePrefix = `rmi-calls-api-${environment.stage}-user-group-`;
          const role = element.replace(rolePrefix, '');
          roles.push(role as UserRole);
        });
        return roles;
      }
    } catch (error) {}
    return [];
  }

  hasAccessToRoute(routeRoles: UserRole[]) {
    return routeRoles.some((x) => this.getRoles().some((y) => y == x));
  }

  async encryptPassword(password: string): Promise<string> {
    const base64String = environment.passwordPublicKeyBase64;
    const publicKey = await atob(base64String);
    return await encryptPassword(password, publicKey);
  }
}
