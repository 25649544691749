import { Component } from '@angular/core';

@Component({
  selector: 'rmi-toolbar-main',
  templateUrl: './toolbar-main.component.html',
  styleUrls: ['./toolbar-main.component.scss']
})
export class ToolbarMainComponent {

  constructor() { }

}
