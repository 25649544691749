import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Captcha } from 'src/app/models/captcha.model';
import { FormState } from 'src/app/models/enums/formState.enum';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'rmi-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss'],
})
export class CaptchaComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  formState = FormState;
  public state$ = new BehaviorSubject(FormState.READY_FOR_SUBMISSION);
  public captchaAnswer: string = '';
  public captchaQuestion: string = '';
  public captchaToken: string = '';
  public errorResponse = 'Answer is required';

  form = this.fb.group({
    answer: ['', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    public dialogRef: DynamicDialogRef
  ) {}

  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.form.disable();
      this.state$.next(FormState.LOADING);
      const answeredCaptcha: Captcha = {
        question: this.captchaQuestion,
        answer: this.form.controls['answer'].value,
        token: this.captchaToken,
      };
      this.authService
        .validateCaptcha(answeredCaptcha)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          complete: () => {
            this.state$.next(FormState.SUBMITTED);
            this.dialogRef.close(answeredCaptcha);
          },
          error: (err) => {
            this.errorResponse = err.message;
            this.state$.next(FormState.ERROR);
            this.form.enable();
          },
        });
    }
  }

  closeCaptchaModal(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.authService
      .getNewCaptcha()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (captchaData: Captcha) => {
          this.captchaQuestion = captchaData.question;
          this.captchaToken = captchaData.token;
        },
        error: (error: HttpErrorResponse) => {
          this.captchaQuestion = 'Failed on fetching captcha data.';
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  onInput(): void {
    this.state$.next(FormState.READY_FOR_SUBMISSION);
  }
}
