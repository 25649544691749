import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.headers.has('Authorization') && !req.headers.has('skip')) {
      return this.authService.validateAccessToken().pipe(
        switchMap((value) => {
          const tokenizedReq = req.clone({
            setHeaders: {
              Authorization: value,
            },
          });
          return next.handle(tokenizedReq);
        })
      );
    } else {
      if (req.headers.has('skip')) {
        req = req.clone({
          headers: req.headers.delete('skip'),
        });
      }
      return next.handle(req);
    }
  }
}
