import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Application } from '../models/applications.model';
import { Call } from '../models/call.model';
import { Collection } from '../models/collection';
import { EmailStatus } from '../models/email-status-model';
import { EmailVerification } from '../models/email-verification';
import { IvrProcessStatus } from '../models/enums/ivr-process-status';
import { TerminateIvrProcess } from '../models/enums/terminateIvrProcess';
import { HealthReport } from '../models/health.model';
import { IvrReportVersionBody } from '../models/ivr-report-version-body.model';
import { IvrReport } from '../models/ivr-report.model';
import { IvrVersionList } from '../models/ivr-version-list.model';
import { Ivr } from '../models/ivr.model';
import { Log } from '../models/log.model';
import { ReportStatus } from '../models/report-status.model';
import { ReportsStatistics } from '../models/reportsStatistics';
import { RoastStatistics } from '../models/roastStatistics';
import { User } from '../models/user.model';
import { Captcha } from '../models/captcha.model';

@Injectable({
  providedIn: 'root',
})
export class HttpRequestsService {
  private ivrsAPI = environment.apiUrl + '/admin/ivrs';
  private ivrsListAPI = this.ivrsAPI + '/details';
  private usersAPI = environment.apiUrl + '/admin/users';
  private callsAPI = environment.apiUrl + '/admin/calls';
  private logsAPI = environment.apiUrl + '/admin/logs';
  private healthAPI = environment.apiUrl + '/admin/health-check/reports';
  private resetPassword = environment.apiUrl + '/admin/reset-password';
  private reportsStatisticsAPI =
    environment.apiUrl + '/admin/report-statistics';
  private roastsPerUserAPI =
    environment.apiUrl + '/admin/roasts-per-user-statistics';

  private emailVerificationsAPI =
    environment.apiUrl + '/admin/email-verifications';

  private ApplicationAdminAPI = environment.apiUrl + '/admin/applications';
  private ApplicationsDashboardApi =
    environment.apiUrl + '/portal/applications';
  // 'https://6665-37-46-122-133.ngrok-free.app/dev/v1'

  constructor(private http: HttpClient) {}
  getIvr(ivrId: string): Observable<Ivr> {
    return this.http.get<Ivr>(`${this.ivrsAPI}/${ivrId}`);
  }

  getIvrReportVersions(ivrId: string): Observable<Collection<IvrVersionList>> {
    return this.http.get<Collection<IvrVersionList>>(
      `${this.ivrsAPI}/${ivrId}/report-versions`
    );
  }

  getAllIvrs(
    startKey?: string,
    status?: IvrProcessStatus,
    search?: string
  ): Observable<Collection<Ivr>> {
    let params = new HttpParams();
    if (startKey) {
      params = params.set('startKey', startKey);
    }
    if (status) {
      params = params.set('status', status);
    }
    if (search) {
      params = params.set('search', search);
    }
    return this.http.get<Collection<Ivr>>(`${this.ivrsAPI}`, {
      params: params,
    });
  }

  getAllUsers(startKey?: string): Observable<Collection<User>> {
    let params = new HttpParams();
    if (startKey) {
      params = params.set('startKey', startKey);
    }
    return this.http.get<Collection<User>>(`${this.usersAPI}`);
  }

  getAllCalls(startKey?: string): Observable<Collection<Call>> {
    let params = new HttpParams();
    if (startKey) {
      params = params.set('startKey', startKey);
    }
    return this.http.get<Collection<Call>>(`${this.callsAPI}`);
  }

  setPassword(old_password: string, new_password: string): Observable<{}> {
    const body = JSON.stringify({ old_password, new_password });
    return this.http.post<{}>(this.resetPassword, body);
  }

  getIvrReports(
    startKey?: string,
    status?: IvrProcessStatus,
    search?: string
  ): Observable<Collection<IvrReport>> {
    let params = new HttpParams();
    if (startKey) {
      params = params.set('startKey', startKey);
    }
    if (status) {
      params = params.set('status', status);
    }
    if (search) {
      params = params.set('search', search);
    }
    return this.http.get<Collection<IvrReport>>(`${this.ivrsListAPI}`, {
      params: params,
    });
  }

  getReportsStatistics(): Observable<ReportsStatistics> {
    return this.http.get<ReportsStatistics>(`${this.reportsStatisticsAPI}`, {});
  }

  getRoastsStatistics({ days }: { days: number }): Observable<RoastStatistics> {
    let params = new HttpParams();
    params = params.set('periodInDays', days);
    return this.http.get<RoastStatistics>(`${this.roastsPerUserAPI}`, {
      params,
    });
  }

  getLog(id: string, timestamp: string): Observable<Log> {
    let params = new HttpParams();
    params = params.set('timestamp', timestamp);
    return this.http.get<Log>(`${this.logsAPI}/${id}`, {
      params,
    });
  }

  getLogs(startKey?: string, search?: string): Observable<Collection<Log>> {
    let params = new HttpParams();
    if (startKey) {
      params = params.set('startKey', startKey);
    }
    if (search) {
      params = params.set('tag', search);
    }
    return this.http.get<Collection<Log>>(`${this.logsAPI}`, {
      params: params,
    });
  }

  getReports(startKey?: string): Observable<Collection<HealthReport>> {
    let params = new HttpParams();
    if (startKey) {
      params = params.set('startKey', startKey);
    }
    return this.http.get<Collection<HealthReport>>(`${this.healthAPI}`, {
      params: params,
    });
  }

  getEmailsVerifications(
    startKey?: string
  ): Observable<Collection<EmailVerification>> {
    let params = new HttpParams();
    if (startKey) {
      params = params.set('startKey', startKey);
    }
    return this.http.get<Collection<EmailVerification>>(
      `${this.emailVerificationsAPI}`
    );
  }

  patchEmailsChangeStatus(inputData: EmailStatus): Observable<EmailStatus> {
    return this.http.patch<EmailStatus>(
      `${this.emailVerificationsAPI}/${inputData.email}/status`,
      {
        verify: inputData.status,
      }
    );
  }

  patchPendingReports(inputData: ReportStatus): Observable<ReportStatus> {
    return this.http.patch<ReportStatus>(
      `${this.ivrsAPI}/${inputData.ivrId}/status`,
      {
        approved: inputData.status,
      }
    );
  }

  patchIvrReportVersion(
    ivrId: string,
    versionId: string
  ): Observable<IvrReportVersionBody> {
    return this.http.patch<IvrReportVersionBody>(
      `${this.ivrsAPI}/${ivrId}/report-versions`,
      {
        versionId: versionId,
      }
    );
  }

  deleteAnIvrReportVersion(
    ivrId: string,
    versionId: string
  ): Observable<IvrReportVersionBody> {
    return this.http.delete<IvrReportVersionBody>(
      `${this.ivrsAPI}/${ivrId}/report-versions/${versionId}`
    );
  }

  addEmailByAdmin(email: string, approved: boolean): Observable<void> {
    return this.http.post<void>(this.emailVerificationsAPI, {
      email: email,
      approved: approved,
    });
  }

  increaseProcessTimeout(ivrId: string): Observable<void> {
    return this.http.patch<void>(
      `${this.ivrsAPI}/${ivrId}/process/increase-timeout`,
      {}
    );
  }

  cancelRetryRoasting(ivrId: string): Observable<void> {
    return this.http.patch<void>(
      `${this.ivrsAPI}/${ivrId}/process/cancel-retry`,
      {}
    );
  }

  terminateIvrProcess(
    ivrId: string,
    task: TerminateIvrProcess
  ): Observable<void> {
    return this.http.patch<void>(`${this.ivrsAPI}/${ivrId}/process/terminate`, {
      task,
    });
  }

  getApplicationsByAdmin(
    startKey?: string,
    search?: string
  ): Observable<Collection<Application>> {
    let params = new HttpParams();
    if (startKey) {
      params = params.set('startKey', startKey);
    }
    if (search) {
      params = params.set('search', search);
    }

    return this.http.get<Collection<Application>>(
      `${this.ApplicationAdminAPI}`,
      {
        params: params,
      }
    );
  }

  getApplicationsDashboard(): Observable<Collection<Application>> {
    return this.http.get<Collection<Application>>(
      `${this.ApplicationsDashboardApi}`
    );
  }

  getApplicationById(applicationId: string): Observable<Application> {
    // /v1/portal/applications/{applicationId}
    return this.http.get<Application>(
      `${this.ApplicationsDashboardApi + '/' + applicationId}`
    );
  }

  editApplicationByDev(applicationId: string, appData: any): Observable<void> {
    // let headers = new HttpHeaders();
    // headers = headers.append('Content-Type', 'multipart/form-data;');
    return this.http.put<void>(`${this.ApplicationsDashboardApi + '/' + applicationId}`, appData);
  }

  addNewApplication(appName: string): Observable<Application> {
    return this.http.post<Application>(this.ApplicationsDashboardApi, {
      applicationName: appName,
    });
  }

  editApplication(appId: string, appName: string): Observable<void> {
    return this.http.patch<void>(`${this.ApplicationsDashboardApi}/${appId}`, {
      applicationName: appName,
    });
  }

  deleteAnApplication(appId: string): Observable<Application> {
    return this.http.delete<Application>(
      `${this.ApplicationsDashboardApi}/${appId}`
    );
  }
}
