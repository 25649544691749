import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Clipboard } from '@angular/cdk/clipboard';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MenuItem, MessageService } from 'primeng/api';
import { Tooltip } from 'primeng/tooltip';


import { Application } from 'src/app/models/applications.model';
import { ApplicationProgramLanguages } from 'src/app/models/applications-program-languages.model'
import { FormState } from 'src/app/models/enums/formState.enum';
import { HttpRequestsService } from 'src/app/services/http-requests.service';

@Component({
  selector: 'rmi-create-new-application',
  templateUrl: './create-new-application.component.html',
  styleUrls: ['./create-new-application.component.scss'],
})
export class CreateNewApplicationComponent implements OnInit, OnDestroy {
  @ViewChildren(Tooltip) tooltips!: QueryList <Tooltip>;
  private readonly destroy$ = new Subject<void>();
  public loading: boolean = true;
  public application: Application[] = [];

  showErrors = false;
  errorMessage?: string;
  formState = FormState;
  public state$ = new BehaviorSubject(FormState.READY_FOR_SUBMISSION);
  form = this.fb.group({
    appName: ['', [Validators.required]],
  });

  constructor(
    private fb: FormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private httpRequests: HttpRequestsService,
    private messageService: MessageService,
    private clipboard: Clipboard,
    private _HttpClient: HttpClient
  ) {}

  programLanguageContent: string = '';
  isActive: number = 0;
  programLanguages: ApplicationProgramLanguages[] = [
    {
      id: 0,
      title: 'Python',
      name: 'python',
    },
    {
      id: 1,
      title: 'Java',
      name: 'java',
    },
    {
      id: 2,
      title: 'TS',
      name: 'typescript',
    },
    {
      id: 3,
      title: 'JS',
      name: 'javascript',
    },
    {
      id: 4,
      title: 'Curl',
      name: 'curl',
    },
    {
      id: 5,
      title: 'C#',
      name: 'csharp',
    }
  ];
  // stepper
  public items: MenuItem[] = [
    {
        label: '1'
    },
    {
        label: '2'
    },
    {
        label: '3'
    }
  ];
  public activeIndex: number = 0;
  public apiKey: string = '';
  public secretKey: string = '';
  ngOnInit() {
    this.getProgramScriptContent('python');
  }

  stepChange(step: number) {
    if(this.activeIndex < 2) {
      this.items[this.activeIndex].styleClass = 'icon check';
      this.activeIndex = step + 1;
    }
  }

  copyClickFlag: boolean = false;
  private findTooltipById(searchId: string): Tooltip | undefined {
    return this.tooltips.find(tooltip => tooltip.el.nativeElement.id === searchId);
  }

  copyText(element: any, text: string) {
    if(this.copyClickFlag) return;
    let elementId = element.id;
    const foundTooltip = this.findTooltipById(elementId);
    this.copyClickFlag = true;
    if (foundTooltip) {
      foundTooltip.activate();
    }
    setTimeout(() => {
      this.copyClickFlag = false;
    }, 1500);
    this.clipboard.copy(text);
  }

  public submit(): void {
    this.loading = true;
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.form.disable();
      this.state$.next(FormState.LOADING);
      this.httpRequests
        .addNewApplication(this.form.controls['appName'].value)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (resp: any) => {
            this.apiKey = resp.apiKeyValue;
            this.secretKey = resp.secretKeyValue;
          },
          complete: () => {
            this.stepChange(0);
            this.loading = false;
            this.form.enable();
            this.state$.next(FormState.SUBMITTED);
          },
          error: (error: HttpErrorResponse) => {
            this.loading = false;
            this.state$.next(FormState.ERROR);
            this.form.enable();
            this.errorMessage = error.message;
            this.messageService.add({
              severity: 'error',
              summary: 'RMI Service',
              detail: 'Adding application failed!\n' + this.errorMessage,
            });
          },
        });
    } else {
      this.showErrors = true;
    }
  }

  saveButton() {
    this.close();
    this.messageService.add({
      severity: 'success',
      summary: 'RMI Service',
      detail: 'Application added successfully!',
    });
  }

  close() {
    this.ref.close();
  }

  ngOnDestroy(): void {
    // this.activeIndex = 0;
    // this.apiKey = '';
    // this.secretKey = '';
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  changeProgramLanguage(selectedPL: any) {
    let plItem = this.programLanguages.findIndex(pl => pl.id === selectedPL);
    this.isActive = selectedPL;
    this.getProgramScriptContent(this.programLanguages[plItem].name);
  }

  private getProgramScriptContent(file_name: string) {
    let file = '/assets/scripts/' + file_name + '.txt';
    this._HttpClient.get(file, { responseType: 'text' })
    .subscribe(
      (response) => {
        this.programLanguageContent = response;
      },
      (error) => {
        this.programLanguageContent = file_name;
      }
    );
  }
}
